import React from 'react';

// Dodane testowo slajdy z rozdziału pierwszego!!!
// TODO: zmienić na właściwe slajdy
import Slide1 from 'src/slides/desktop/hu/chapter_2/sub_1/slide1';
import Slide2 from 'src/slides/desktop/hu/chapter_2/sub_1/slide2';

import WrapperMobile from 'src/slides/mobile/hu/chapter_2/sub_1/wrapperMobile';
import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

const allSlides = [<Slide1 />, <Slide2 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Az októberi forradalom | A varsói csata" lang="hu" description="A bolsevik rebellió története, mely megváltoztatta a XX. század történelmét." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Az októberi forradalom | A varsói csata" lang="hu" description="A bolsevik rebellió története, mely megváltoztatta a XX. század történelmét." />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
